import { ref as valtioRef } from "valtio";

import { IS_NODE, IS_TESTING } from "@/__main__/constants.mjs";
import {
  destroyAdHooks,
  videoAdsMap,
} from "@/feature-ads/constants/constants.mjs";
import {
  createPlaceholder,
  destroyAd,
  onResize,
  startObserving as startObservingVideo,
  stopObserving as stopObservingVideo,
} from "@/feature-ads/global-video-ad.mjs";
import { setupPostmatchPlaceholder } from "@/feature-ads/postmatch-placeholder.mjs";
import { setupProfilePlaceholder } from "@/feature-ads/profile-placeholder.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import matchRefs from "@/shared/Match.refs.jsx";
import overlayRefs from "@/shared/OverlayContainerWithAd.refs.jsx";
import profileRefs from "@/shared/Profile.refs.jsx";
import { appLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const original = mapOriginalRefs({
  matchRefs,
  profileRefs,
});

const cleanup = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destroyAdHooks: null as Map<object, Record<string, any>> | null,
  destroyAd: null as ((name: string) => void) | null,
};

function shouldEnableAds() {
  if (IS_TESTING) return true;
  // This is disabled so that we render the placeholders on SSR.
  // if (IS_NODE) return false;
  return true;
}

export function setup() {
  if (!shouldEnableAds()) return;
  appLog(`[video-ads] setting up ${videoAdsMap.size} video ads`);
  videoAdsMap.forEach((data, ref) => {
    // special case: in-game ads must be disabled if adsRefs.showIngameVideo is
    // false
    // TODO This may not work with the timing of CMS flags being fetched, ok for now.
    if (!adsRefs.showIngameVideo && data.pageMacro === "in-game") return;

    const placeholder = createPlaceholder(data);
    if (!placeholder) return;
    ref[data._refKey || "videoAd"] = valtioRef(placeholder);
  });

  original.set({
    matchRefs: {
      setupPlaceholder: setupPostmatchPlaceholder,
    },
  });

  original.set({
    profileRefs: {
      setupPlaceholder: setupProfilePlaceholder,
    },
  });

  destroyAdHooks.forEach(([obj, key]) => {
    const ogmap = cleanup.destroyAdHooks?.get(obj) || {};

    (cleanup.destroyAdHooks ??= new Map()).set(
      obj,
      Object.assign(ogmap, {
        [key]: obj[key],
      }),
    );

    obj[key] = () => destroyAd(key);
  });

  cleanup.destroyAd = overlayRefs.destroyAd;
  overlayRefs.destroyAd = destroyAd;

  if (!IS_NODE) globals.addEventListener?.("resize", onResize);
  startObservingVideo();
}

export function teardown() {
  if (!shouldEnableAds()) return;
  destroyAd("feature teardown");

  videoAdsMap.forEach((data, ref) => {
    ref[data._refKey || "videoAd"] = null;
  });

  destroyAdHooks.forEach(([obj, key]) => {
    const original = cleanup.destroyAdHooks.get(obj);
    obj[key] = original[key];
  });

  overlayRefs.destroyAd = cleanup.destroyAd;

  if (!IS_NODE) globals.removeEventListener("resize", onResize);

  stopObservingVideo();
}
